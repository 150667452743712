<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-4 container">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE LOADER (Ergonomic Type).jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h5 class="font-size-16 mb-3">
                    <strong> Product description: </strong>
                  </h5>
                  <h6>
                  This is Magazine Loader to protect the operator from backache.
                  </h6>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />

            <div class="mt-4 container">
              <h5 class="font-size-14">Related Products :</h5>
              <div class="container fluid">
                <div class="row" style="margin-top: 50px">
                  <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
                      to="/production_equipement/board_handling_system/loading_unloading/magazine_loader_standard"
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE LOADER (Standard Type).jpg"
                          alt="Card image cap"
                        />

                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                            MAGAZINE LOADER (Standard Type)
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                  <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
                      to="/production_equipement/board_handling_system/loading_unloading/magazine_loader_90"
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE LOADER (90˚ Type).jpg"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                            MAGAZINE LOADER (90˚ Type)
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                  <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
           to="/production_equipement/board_handling_system/loading_unloading/bare_board_loader">
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/BARE BOARD LOADER(VACUUM PICK-UP TYPE).jpg"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                BARE BOARD LOADER(VACUUM PICK-UP TYPE)
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                  <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
                      to="/production_equipement/board_handling_system/loading_unloading/magazine_vacuum_loader"
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE & VACUUM LOADER(Combination).jpg"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                            MAGAZINE & VACUUM LOADER(Combination)
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                  <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
                      to="/production_equipement/board_handling_system/loading_unloading/single_magazine_loader"
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/SINGLE MAGAZINE LOADER (CE Type).jpg"
                          alt="Card image cap"
                        />

                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                            SINGLE MAGAZINE LOADER (CE Type)
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                  <div class="col-md-6 col-xl-2">
                    <!-- Simple card -->
                    <router-link
                      to="/production_equipement/board_handling_system/loading_unloading/dual_magazine_loader"
                    >
                      <div class="card" style="width: 160px; height: 200px">
                        <img
                          style="max-height: 80%; max-width: 100%; margin: auto"
                          class="card-img-top img-fluid"
                          src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/DUAL MAGAZINE LOADERUNLOADER(CE Type).jpg"
                          alt="Card image cap"
                        />

                        <div class="card-body">
                          <h4 style="text-align: center" class="card-title">
                            DUAL MAGAZINE LOADER/UNLOADER(CE Type)
                          </h4>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- end col -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from ".././../../../../components/Header.vue";

import Footer from ".././../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>